@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
/* overflow-y: hidden; */
overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.bg-gray-primary {
  background-color: #752b90;
}

ul li {
  border-bottom: 0.1px solid rgba(255, 255, 255, 0.472);
  padding: 15px 0;
}

.mgq-primary-light {
  color: #582368
}

.mgq-primary-dark {
  color: #582368
}

.mgq-bg-primary-dark {
  background-color: #582368
}

.mgq-primary {
  color: #752b90
}

.mgq-bg-primary {
  background-color: #752b90
}

.mgq-bg-primary:hover {
  background-color: #582368
}
